import React, { useEffect, useState } from "react";
import styles from "./DeliveredHistory.module.css";
import {
  NavigateNext,
  NavigateBefore,
  Upcoming,
  SwipeRight,
  Refresh,
} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import { REACT_APP_BASE_URL as baseUrl } from "../../config/keys";
import { ClipLoader } from "react-spinners";
import OrderDetailsModal from "../orderDetailsModal/OrderDetailsModal";
import moment from "moment/moment";
import OrderPaymentDetails from "../orderPaymentDetails/OrderPaymentDetails";
import FilterDate from "../filterDate/FilterDate";
import DeliveredOrdersDetails from "../deliveredOrdersDetails/DeliveredOrdersDetails";

const DeliveredHistory = ({pageName}) => {
  
  const mart=JSON.parse(localStorage.getItem('@userData'))
  // console.log("🚀 ~ file: HistoryCard.js:20 ~ HistoryCard ~ mart:", mart)

  const [data, setData] = useState([]);
  
  const [zones, setZones] = useState();
  const [recordPerPage, setRecordPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [paginateRecord, setPaginateRecord] = useState();
  const [refresh, setRefresh] = useState(false);
  const [modal, setModal] = useState(false);
  const [ordersData, setOrdersData] = useState();
  const [loading, setLoading] = useState(true);
  const [notifiedStatus, setNotifiedStatus] = useState("Notify to Customer");
  const [filter,setFilter]=useState(false)
  const [deliveredData,setDeliveredData]=useState()
  const [isFilter,setIsFilter]=useState(false)

  useEffect(()=>{
    getDeliveredOrders()
  },[refresh])
 const getDeliveredOrders= async(startDate,endDate)=>{
    setLoading(true)
    try{
    const result = await axios.post(`${baseUrl}/orders/deliveredOrders`,{
      martId:mart.data._id,
      startDate,
      endDate,
      filter:isFilter
    })
      
    if(result.data.status==='200'){
      // console.log("🚀 ~ file: DeliveredHistory.js:57 ~ result.data:", result.data)
      setCurrentPage(1)
     setDeliveredData(result.data)
     setData(result.data.deliveredOrders)
    }
    setLoading(false)
    }catch(e){
   console.log(e)
    }
  }


  useEffect(() => {
    if (data) {
      const totalPages = Math.ceil(data?.length / recordPerPage);
      setTotalPages(totalPages);
      const indexOfLastRecord = currentPage * recordPerPage;
      const indexOfFirstRecord = indexOfLastRecord - recordPerPage;
      const record = data?.slice(indexOfFirstRecord, indexOfLastRecord);
      setPaginateRecord(record);
    }
  }, [data, currentPage]);

  const pageNext = () => {
    if (currentPage !== totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const pageBack = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  //>>>>>>>>>>>>>>>>>>>>> Pagination <<<<<<<<<<<<<<<<<<<//

 
  //>>>>>>>>>>>>>>>>>>>Update The page<<<<<<<<<<<<<<<<//


  //>>>>>>>>>>>>>>>>>>>Update The page<<<<<<<<<<<<<<<<<<//

  return (
    <div className={styles.trackingCard}>
      <div className={styles.cardHeader}>
        <h2>{pageName}</h2>
         <div>
          <FilterDate
          isFilter={isFilter}
          makeFilter={()=>setIsFilter(!isFilter)}
          startDate1={deliveredData?.deliveredOrders[0]?.date}
          endDate1={deliveredData?.deliveredOrders[deliveredData?.deliveredOrders.length - 1]?.date}
          searchOrders={getDeliveredOrders}
        />
         </div>
        </div>
      <div className={styles.subHeader}>
        <h2>Order No</h2>
        <h2>Date&Time</h2>
        <h2>Location</h2>
        <h2>Amount</h2>
        <h2>Order Status</h2>
        <h2>Type</h2>
        <h2>Details</h2>
      </div>
      <div className={styles.itemsDataContainer}>
        {loading && (
          <div className={styles.loading}>
            <ClipLoader color="#ff7378" size={50} />
          </div>
        )}
        {paginateRecord?.length > 0 && !loading ? (
          paginateRecord?.map((item) => (
            <div key={item._id} className={styles.item}>
              <span style={{ marginLeft: "0px" }}>{item.orderNum}</span>
              <span style={{ marginRight: "0px" }}>
               {item.date}
               <br/>
               {item.time}
              </span>
              <span style={{ textAlign: "" }}>
               {item.address}
              </span>
              <span style={{ textAlign: "", color: "mediumseagreen" }}>
                {item.orderTotal}
              </span>
              <span style={{ textAlign: "", }}>
                {item.status}
              </span>
              <span style={{ textAlign: "",  }}>
                {item.orderType}
              </span>
           
              <span
                onClick={() => [
                  setModal(true),
                  setOrdersData(item)
                  // setOrderData(item),
                  // dispatch(addOrder(item)),
                ]}
                style={{ textAlign: "right", color: "red", padding: "10px" }}
              >
                View Details
              </span>
            </div>
          ))
        ) : paginateRecord?.length <= 0 && !loading ? (
          <div className={styles.showTitle}>
            <h5>Nothing To Show</h5>
          </div>
        ) : null}
      </div>
      <div className={styles.pagination}>
        <div className={styles.deliveredOrdersDetails}>
         <div className={styles.orderDetailBox}>
            <h5>Total Pay:</h5>
             <span>{deliveredData?.totalToPay}</span>
         </div>
         <div className={styles.orderDetailBox}>
            <h5>Total Sales:</h5>
             <span>{deliveredData?.netSale}</span>
         </div>
         <div className={styles.orderDetailBox}>
            <h5>Regular:</h5>
             <span>{deliveredData?.nonDealPayment}</span>
         </div>
         <div className={styles.orderDetailBox}>
            <h5>Deal:</h5>
             <span>{deliveredData?.dealPayment}</span>
         </div>
         <div className={styles.orderDetailBox}>
            <h5>Total Delivered:</h5>
             <span>{deliveredData?.deliveredOrders?.length}</span>
         </div>
        </div>
        <div className={styles.paginationBox}>
          <button
            disabled={paginateRecord?.length < 1 ? true : false}
            onClick={() => pageBack()}
            className={styles.back}
          >
            <NavigateBefore />
          </button>
          <div className={styles.status}>
            <span>{currentPage}</span>
          </div>
          <button
            disabled={paginateRecord?.length < 1 ? true : false}
            onClick={() => pageNext()}
            className={styles.next}
          >
            <NavigateNext />
          </button>
        </div>
      </div>
      {modal && (
        <div className={styles.modal}>
          <div className={styles.newModal} />
          <DeliveredOrdersDetails
          modalClose={()=>setModal(false)}
          data={ordersData}
          />
        </div>
      )}
    </div>
  );
};

export default DeliveredHistory;
