import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SideBar from "../../components/sideBar/SideBar";
import styles from "./PaymentsHistory.module.css";
import HistoryCard from "../../components/historyCard/HistoryCard";
const PaymentsHistory = ({ docTitle }) => {


   useEffect(()=>{
   document.title=docTitle
   },[])
  return (
    <div className={styles.orderTracking}>
      <div className={styles.sidebar}>
        <SideBar  />
      </div>
      <div className={styles.trackingCard}>
        <HistoryCard pageName="Payments History" />
      </div>
    </div>
  );
};

export default PaymentsHistory;
