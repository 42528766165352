import React, { useState } from 'react'
import styles from './UpdateProducts.module.css'
import { Button, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { REACT_APP_BASE_URL  as baseUrl} from '../../config/keys';
import { ProductState } from '../../context/ProductProvider';
const UpdateProducts = ({close,selectedProduct}) => {
  // console.log('selectedProduct',selectedProduct)
 const {refresh,setRefresh}=ProductState()
const [productName,setProductName]=useState(selectedProduct.productName)
const [productPrice,setProductPrice]=useState(selectedProduct.price)
const updateProduct=async()=>{
  if(productName && productPrice){
    try{
      const result = await axios.post(`${baseUrl}/products/editProduct`,{
       productName,
       price:productPrice,
       productId:selectedProduct._id
      })
      if(result.data.status==='200'){
        alert(result.data.msg)
      }
      close()
      setRefresh(!refresh)
     }catch(e){
     console.log(e)
     }
  }else{
    alert('Please Fill all the fields')
  }

}
  return (
    <div className={styles.updateProducts}>
        <div className={styles.header}>
          <h5>Update Product</h5>
          <IconButton onClick={close}>
            <CloseIcon/>
          </IconButton>
        </div>
        <div className={styles.updateProduct_body}>
         <div className={styles.updateProducts_inputs}>
         <h6>Product*</h6>

         <div className={styles.UpdateProducts_inputBox}>
         <input placeholder='Update Your Product Name' 
         value={productName}
         onChange={(e)=>setProductName(e.target.value)}
         />
         </div>
        
         </div>
         <div className={styles.updateProducts_inputs}>
         <h6>Price*</h6>
         <div className={styles.UpdateProducts_inputBox}>
         <input placeholder='Update Your Product Price' 
         onChange={(e)=>setProductPrice(e.target.value)}
         value={productPrice}
         />
         </div>
      
         </div>
         <div className={styles.updateProducts_button}>
             <Button
             onClick={updateProduct} 
             sx={{
                backgroundColor:'#ff7378',
                color:'#ffff',
                padding:'10px 30px',
                width:'50%',
                '&:hover':{
                    backgroundColor:'#fff',
                    color:'#ff7378',
                    border:'1px solid #ff7378'
                }
             }}
             >
          Update
         </Button>
         </div>
       
        </div>
    </div>
  )
}

export default UpdateProducts