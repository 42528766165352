import React from 'react'
import styles from './DeliveredOrder.module.css'
import { IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'
const DeliveredOrdersDetails = ({modalClose,data}) => {
  console.log("🚀 ~ file: DeliveredOrdersDetails.js:6 ~ DeliveredOrdersDetails ~ data:", data)
  return (
    <div className={styles.deliveredOrdersDetails}>
        <div className={styles.header}>
        <h2>Order Details</h2>
        <IconButton onClick={modalClose} >
          <Close className={styles.close} />
        </IconButton>
      </div>
      <div className={styles.orderDetails}>
        <div className={styles.orderNo}>
       <h5>Order No</h5>
        </div>
        <div className={styles.orderNoText}>
        <h6>{data.orderNum}</h6>
        </div>
        <div className={styles.product}>
       <h5>Products</h5>
        </div>
        <div className={styles.productItemContainer}>
        {data?.products?.map((item)=>(
        <div className={styles.productItem}>
        <h6>{item.count}
        <span>x</span>
        </h6>
        <h6>{item.productName}</h6>
        </div>
      )) }
          </div>
        
        <div className={styles.customerInfo}>
          <h5>Customer</h5>
        </div>
        <div className={styles.customerData}>
        <h6>{data?.name}</h6>
        <h6 className={styles.address}>{data.address}</h6>
        </div>
      </div>

    </div>
  )
}

export default DeliveredOrdersDetails