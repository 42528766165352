import React,{useEffect} from 'react'
import styles from './RejectedOrders.module.css';
import { useNavigate } from "react-router-dom";
import SideBar from '../../components/sideBar/SideBar';
import HistoryCard from '../../components/historyCard/HistoryCard';
import RejectedOrderCard from '../../components/rejectedOrderCard/RejectedOrderCard';

const RejectedOrders = ({docTitle}) => {
 

   useEffect(()=>{
   document.title=docTitle
   },[])
  return (
    <div className={styles.orderTracking}>
      <div className={styles.sidebar}>
        <SideBar  />
      </div>
      <div className={styles.trackingCard}>
        <RejectedOrderCard pageName="Rejected Orders" />
      </div>
    </div>
  )
}

export default RejectedOrders