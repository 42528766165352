import React, { useEffect, useState } from "react";
import { Search, Whatshot, Add,Edit, Height, Block } from '@mui/icons-material';
import { Badge, IconButton } from "@mui/material";
import styles from './Products.module.css'
import UpdateProducts from "../updateProducts/UpdateProducts";
import { ClipLoader } from "react-spinners";
import axios from "axios";
import { REACT_APP_BASE_URL as baseUrl } from "../../config/keys";
import { ProductState } from "../../context/ProductProvider";
import prod from "../../config/prod";
const Products = ({ restaurantModalClose, restaurantName,products }) => {


  const [updateProduct,setUpdateProduct]=useState(false)
  const [cart, setCart] = useState(false)
  const [cartCount, setCartCount] = useState(0)
  const [borderName, setBorderName] = useState('')
  const [charges, setCharges] = useState()
  const {refresh,setRefresh}=ProductState()
  const [selectedProduct,setSelectedProduct]=useState()
  const [loading,setLoading]=useState(false)
  const [searchBox,setSearchBox]=useState(false)
  const [searchText,setSearchText]=useState()
  const [filteredProducts,setFilteredProducts]=useState()


  useEffect(()=>{
    setFilteredProducts(products?.data?.map(item=>item.data))
  },[products])


const searchProducts=(query)=>{
  var updatedList=products.data.map(item=>item.data)
   updatedList=updatedList?.map(item=>item?.filter(product=>product?.productName?.toLowerCase().indexOf(query.toLowerCase()) > -1))
   setFilteredProducts(updatedList)

  }


  const isScroll = (refName) => {
    var element = document.getElementById(refName)
    element?.scrollIntoView()
  }
      const productStock=async(e,item)=>{
        if(e.detail===2){
          setLoading(true)
         try{
        const result=await axios.post(`${baseUrl}/products/updateSingleProduct`,{
          productId:item._id,
          available:item.available==='out of stock'?'in stock':'out of stock'
        })
        setLoading(false)
        if(result.data.status==='200'){
          alert(`Your Product has been ${item.available==='out of stock'?'in stock':'out of stock'}`)
          setRefresh(!refresh)
        }
        // console.log('result',result)
         }catch(e){
             console.log(e)
         }
        }
        // setIsStock(e._id)
      }
  return <div

    className={styles.restaurantModal}
>
    <div className={styles.headerContainer}>
      
      <div
        className={styles.iconContainer}

      >
        <IconButton
          onClick={() => setCart(true)}
        >
       
        </IconButton>


        </div>


    </div>
    <div className={styles.searchContainer}>
      <div className={styles.search}>
        <IconButton>
        <Search
          className={styles.searchIcon}
        />
        </IconButton>
     
        <input
        onFocus={()=>setSearchBox(true)}
        onChange={(e)=>searchProducts(e.target.value)}
        type="text" placeholder="Search" className={styles.inputBox} />
      </div>
       {/* {
        searchBox&&
        <div className={styles.searchBox}>
          <div>
            <h6>Search for "{}"</h6>
          </div>
        </div>
       } */}
    </div>
    <div className={styles.categoryList}>
      {products?.data?.map((item) => (
        <button
          style={{ borderBottom: borderName == item.category ? '2px solid #ff7378':'white'}}
          onClick={() => [setBorderName(item.category), isScroll(item.category)]}
        >
          <h5>
            {item.category}
          </h5>
        </button>

      ))}
    </div>
    <div className={styles.categoryContainer}>
     {filteredProducts?.length > 0 ?
      
     filteredProducts?.map((product) => (
        <div
          id={product[0]?.category}
        >
       {product?.length>0&&
          <div className={styles.title}>
            <Whatshot
              className={styles.shotIcon}
            />
            <h5>
              {product[0]?.category}
            </h5>
          </div>}

          <div className={styles.productItemContainer}>

            {product?.map((item) => (
              <div className={styles.product}
              onClick={(e)=>productStock(e,item)}
              >
                <div className={styles.contentContainer}>
                  <h5
                  >{item?.productName}
                  </h5>
                  <h5
                    style={{ fontSize: "13px", color: "rgba(0,0,0,0.8)" }}
                  >
                    {item?.quantity}
                  </h5>
                  <div style={{ display: "flex", textAlign: 'center' }}>
                    <h5>from</h5>
                    <h5
                      style={{
                        // textDecorationLine: item.discount == "0" ? 'none' : 'line-through',
                        textAlign: 'center',
                        marginLeft:"5px"

                      }}
                    >
                      Rs.{item?.price}
                    </h5>
                   
                  </div>
                 
                </div>
                {item.available==='out of stock'?
                <div  className={styles.blockIcon}>
                    <IconButton
                >
                 <Block
                   sx={{
                     color:'#ff7378'
                   }}
                 />
               </IconButton>
                </div>
               :
               <>
               </>}
               <div
                 className={styles.plusIcon}
               >
               <IconButton
                onClick={()=>{setUpdateProduct(true)
                setSelectedProduct(item)
                }}
                >
                  <Edit
                  />
                </IconButton>
               </div>
              
                {/* <div className={styles.icon_container}>
             
                </div>
             */}

              </div>
            ))} 
          </div>

        </div>
      )):
      <div style={{display:'flex',justifyContent:'center',marginTop:'110px'}}>
        <ClipLoader  
        color="#ff7378" size={40}
        />
      </div>
      }
    </div>
    {updateProduct &&
      <div className={styles.modalContainer}>
        <UpdateProducts
        close={()=>setUpdateProduct(false)}
        selectedProduct={selectedProduct}
        />
      </div>
    }
  {loading&&  
          <div
           className={styles.loading}
          >
         <ClipLoader
        color="#ff7378" size={40}
         
         />
    </div>}
  </div>;
};

export default Products;
