import React, { useEffect } from 'react';
import SideBar from '../../components/sideBar/SideBar';
import styles from './TrackingOrders.module.css';
import TrackingCard from '../../components/trackingCard/TrackingCard';
import OneSignal from 'react-onesignal';
import { REACT_APP_BASE_URL as baseUrl } from '../../config/keys';
import axios from 'axios';
const TrackingOrders = ({ docTitle }) => {
  const userData = JSON.parse(localStorage.getItem('@userData'));
  useEffect(() => {
    (async () => {
      await OneSignal.init({ appId: '3b74637a-269d-4899-ac1b-bb0369fec849' });
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await OneSignal.User.PushSubscription.addEventListener(
        'change',
        async (isSubscribed) => {
          if (isSubscribed?.current?.optedIn && isSubscribed?.current?.id) {
            const { id } = isSubscribed?.current;
            try {
              const result = await axios.post(
                `${baseUrl}/general/restaurantWebPlayerId`,
                {
                  userId: userData?.data?._id,
                  webPlayerId: id,
                }
              );
              if (result.data.status === '200') {
                console.log('Web Player Id Updated');
              }
            } catch (error) {
              console.log(error);
            }
          }
        }
      );
    })();
  }, []);
 
  useEffect(() => {
    document.title = docTitle;
  }, []);
  return (
    <div className={styles.orderTracking}>
      <div className={styles.sidebar}>
        <SideBar />
      </div>
      <div className={styles.trackingCard}>
        <TrackingCard />
      </div>
    </div>
  );
};

export default TrackingOrders;
