import React,{useEffect} from 'react'
import styles from './OrdersHistory.module.css'
import SideBar from '../../components/sideBar/SideBar'
import HistoryCard from '../../components/historyCard/HistoryCard';
import { useNavigate } from "react-router-dom";
import OrdersHistoryCard from '../../components/ordersHistoryCard/OrdersHistoryCard';
const OrdersHistory = ({docTitle}) => {
     useEffect(()=>{
     document.title=docTitle
     },[])
  return (
    <div className={styles.orderTracking}>
    <div className={styles.sidebar}>
      <SideBar  />
    </div>
    <div className={styles.trackingCard}>
      <OrdersHistoryCard pageName="Orders History" />
    </div>
  </div>
  )
}

export default OrdersHistory