import React,{useEffect} from 'react'
import styles from './DeliveredOrders.module.css'
import SideBar from '../../components/sideBar/SideBar'
import HistoryCard from '../../components/historyCard/HistoryCard'
import { useNavigate } from "react-router-dom";
import DeliveredHistory from '../../components/deliveredHistory/DeliveredHistory';
const DeliveredOrders = ({docTitle}) => {

     useEffect(()=>{
     document.title=docTitle
     },[])
  return (
    <div className={styles.orderTracking}>
    <div className={styles.sidebar}>
      <SideBar  />
    </div>
    <div className={styles.trackingCard}>
      <DeliveredHistory pageName="Delivered Orders"/>
    </div>
    </div>
  )
}

export default DeliveredOrders