import React, { useEffect, useState } from "react";
import { Menu, Lock, AccountBalance, Home, Group, Person, RestaurantMenu,Payments, DeliveryDining, ThumbDown, History } from "@mui/icons-material";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Link, useNavigate } from "react-router-dom";
import "./SideBar.css";
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import { ProductState } from "../../context/ProductProvider";
import { IconButton } from "@mui/material";
// import { useDispatch } from "react-redux";
// import { removeData } from "../../features/dashboardSlice";
function SideBar({ sidebarGo }) {
  const [sideBarClass,setSideBarClass]=useState(false)
//   const dispatch = useDispatch()

  const userData = JSON.parse(localStorage.getItem('@userData'))
  const {refresh,setRefresh}=ProductState()
  // console.log("🚀 ~ file: SideBar.js:10 ~ SideBar ~ userData:", userData)
  const navigate = useNavigate()
  useEffect(()=>{
    if(!userData){
     navigate('/login')
    }
   },[])
  const signOut = () => {
    localStorage.removeItem('@userData')
    navigate('/')
    setRefresh(!refresh)
  }
  return (
    <>
    <div className={sideBarClass ? 'sideBarGo' : "sidebar"}>
      <div className="sidebar__container">

        <Menu 
        onClick={()=>setSideBarClass(false)}
        sx={{ fontSize: "30px", color: "white", cursor: "pointer" }} />
        <Link to="/tracking" className="link">
          <Home
            className="icon"
            sx={{ fontSize: "30px", color: "white", cursor: "pointer" }}
          />
          <h5 className="text">Home</h5>
        </Link>
        <Link to="/menuScreen" className="link">
          <RestaurantMenu
            sx={{ color: "white", fontSize: "30px", cursor: "pointer" }}
          />
          <h5 className="text" >Menu</h5>
        </Link>
        <Link to='/deliveredOrders' className="link">
        <DeliveryDining sx={{ color: "white", fontSize: "30px", cursor: "pointer",marginLeft:'2px' }}/>
        <h5 className="text">Delivered</h5>
        </Link>
        <Link to='/paymentsHistory' className="link">
        <Payments sx={{ color: "white", fontSize: "30px", cursor: "pointer",marginLeft:'2px' }}/>
        <h5 className="text">Payments</h5>
        </Link>
      
        <Link to='/ordersHistory' className="link">
        <History sx={{ color: "white", fontSize: "30px", cursor: "pointer",marginLeft:'2px' }}/>
        <h5 className="text">History</h5>
        </Link>
        <Link to='/rejectedOrders' className="link">
        <ThumbDown sx={{ color: "white", fontSize: "30px", cursor: "pointer",marginLeft:'2px' }}/>
        <h5 className="text">Rejected</h5>
        </Link>
        <div
          onClick={signOut}
          className="link">
          <Lock sx={{ fontSize: "30px", color: "white", cursor: "pointer" }} />
          <h5 className="text">Logout</h5>
        </div>

      </div>
    </div>
    <div className={sideBarClass?'':'sideBar_menu'}>
      <IconButton
      onClick={()=>setSideBarClass(true)}
      >
      <Menu sx={{ fontSize: "30px", cursor: "pointer" }} />
      </IconButton>
      </div>
    </>
    
  );
}

export default SideBar;
