import React, { useEffect, useState } from "react";
import styles from "./HistoryCard.module.css";
import {
  NavigateNext,
  NavigateBefore,
  Upcoming,
  SwipeRight,
  Refresh,
} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import { REACT_APP_BASE_URL as baseUrl } from "../../config/keys";
import { ClipLoader } from "react-spinners";
import OrderDetailsModal from "../orderDetailsModal/OrderDetailsModal";
import moment from "moment/moment";
import OrderPaymentDetails from "../orderPaymentDetails/OrderPaymentDetails";

const HistoryCard = ({pageName}) => {
  
  const mart=JSON.parse(localStorage.getItem('@userData'))
  // console.log("🚀 ~ file: HistoryCard.js:20 ~ HistoryCard ~ mart:", mart)

  const [data, setData] = useState([]);
  
  const [zones, setZones] = useState();
  const [recordPerPage, setRecordPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [paginateRecord, setPaginateRecord] = useState();
  const [refresh, setRefresh] = useState(false);
  const [modal, setModal] = useState(false);
  const [ordersData, setOrdersData] = useState();
  const [loading, setLoading] = useState(true);
  const [notifiedStatus, setNotifiedStatus] = useState("Notify to Customer");
  
  // const dispatch = useDispatch();
  // const refreshThePage = useSelector(selectRefresh);
  // const userData = JSON.parse(localStorage.getItem("userLogin"));


  //>>>>>>>>>>>>>>>>>>>>> Pagination <<<<<<<<<<<<<<<<<<<//
   
  useEffect(()=>{
  (async()=>{
   try{
   const result=await axios.post(`${baseUrl}/marts/paymentHistory`,{
    martId:mart.data._id
 
   })
  //  console.log("🚀 ~ file: HistoryCard.js:45 ~ result:", result)
   if(result.data.status==='200'){
    setData(result.data.data)
   }
 
   setLoading(false)

     }catch(e){
    console.log(e)
   }
  })()
  },[refresh])

  useEffect(() => {
    if (data) {
      const totalPages = Math.ceil(data?.length / recordPerPage);
      setTotalPages(totalPages);
      const indexOfLastRecord = currentPage * recordPerPage;
      const indexOfFirstRecord = indexOfLastRecord - recordPerPage;
      const record = data?.slice(indexOfFirstRecord, indexOfLastRecord);
      setPaginateRecord(record);
    }
  }, [data, currentPage]);

  const pageNext = () => {
    if (currentPage !== totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const pageBack = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  //>>>>>>>>>>>>>>>>>>>>> Pagination <<<<<<<<<<<<<<<<<<<//

 
  //>>>>>>>>>>>>>>>>>>>Update The page<<<<<<<<<<<<<<<<//
  const pageRefresh = () => {
    setLoading(true);
    setModal(false);
  };
  const updatePage = () => {
    setRefresh(!refresh);
  };

  //>>>>>>>>>>>>>>>>>>>Update The page<<<<<<<<<<<<<<<<<<//

  return (
    <div className={styles.trackingCard}>
      <div className={styles.cardHeader}>
        <h2>{pageName}</h2>
        </div>
      <div className={styles.subHeader}>
        <h2>From</h2>
        <h2>To</h2>
        <h2>Paid Amount</h2>
        <h2>Order Count</h2>
        <h2>Details</h2>
      </div>
      <div className={styles.itemsDataContainer}>
        {loading && (
          <div className={styles.loading}>
            <ClipLoader color="#ff7378" size={50} />
          </div>
        )}
        {paginateRecord?.length > 0 && !loading ? (
          paginateRecord?.map((item) => (
            <div key={item._id} className={styles.item}>
              <span style={{ marginLeft: "0px" }}>{moment(item.startDate).format('L')}</span>
              <span style={{ marginLeft: "0px" }}>
                {moment(item.endDate).format('L')}
               
              </span>
              <span style={{ textAlign: "" }}>
                {item?.paidAmount?.toLocaleString()}
              </span>
              <span style={{ textAlign: "center", color: "mediumseagreen" }}>
                {item.orderCount}
              </span>
           
              <span
                onClick={() => [
                  setModal(true),
                  setOrdersData(item)
                  // setOrderData(item),
                  // dispatch(addOrder(item)),
                ]}
                style={{ textAlign: "right", color: "red", padding: "10px" }}
              >
                View Details
              </span>
            </div>
          ))
        ) : paginateRecord?.length <= 0 && !loading ? (
          <div className={styles.showTitle}>
            <h5>Nothing To Show</h5>
          </div>
        ) : null}
      </div>
      <div className={styles.pagination}>
        <div className={styles.paginationBox}>
          <button
            disabled={paginateRecord?.length < 1 ? true : false}
            onClick={() => pageBack()}
            className={styles.back}
          >
            <NavigateBefore />
          </button>
          <div className={styles.status}>
            <span>{currentPage}</span>
          </div>
          <button
            disabled={paginateRecord?.length < 1 ? true : false}
            onClick={() => pageNext()}
            className={styles.next}
          >
            <NavigateNext />
          </button>
        </div>
      </div>
      {modal && (
        <div className={styles.modal}>
          <div className={styles.newModal} />
          <OrderPaymentDetails
            modalClose={() => setModal(false)}
            orderData={ordersData}
            percentage={mart.data.percentage}
          />
        </div>
      )}
    </div>
  );
};

export default HistoryCard;
