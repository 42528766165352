import React, { useEffect } from 'react'
import styles from './Splash.module.css'
import { BarLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'
const Splash = () => {
    const navigate=useNavigate()
   useEffect(()=>{
   const data=JSON.parse(localStorage.getItem('@userData'))
   setTimeout(()=>{
      if(data){
        navigate('/tracking')
       }else{
        navigate('/login')
       }
   },1000)
  

   },[])

  return (
    <div className={styles.splash}>
        <img src={require('../../assets/dastak.png')} alt=""  />
        <BarLoader
          color="#ff7378"
          loading={true}
          size={50}
          aria-label="Loading Spinner"
          data-testid="loader"
        />

    </div>
  )
}

export default Splash