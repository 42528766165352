import React, { useEffect, useState } from "react";
import styles from "./OrderPaymentDetails.module.css";
import {
  Close,
  ExpandLess,
  ExpandMore,
  Person,
  LocalPhone,
  FmdGood,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";

import axios from "axios";
import { REACT_APP_BASE_URL as baseUrl } from "../../config/keys";
import moment from "moment";
const OrderPaymentDetails = ({
  modalClose,
  orderData,
  percentage
}) => {
  // console.log("🚀 ~ file: OrderPaymentDetails.js:21 ~ orderData:", orderData)
 const [ordersDetails,setOrdersDetails]=useState()
  
 
  useEffect(()=>{
  (async()=>{
    try{
      const result=await axios.post(`${baseUrl}/marts/previousPayments`,{
        startDate:moment(orderData.startDate).format('DD-MM-Y'),
        endDate:moment(orderData.endDate).format('DD-MM-Y'),
        martId:orderData.martId,
        percentage
       })
      //  console.log('paymentsDetails',result)
       if(result.data.status==='200'){
          setOrdersDetails(result.data)
       }
    }catch(e){
     console.log('e',e)
    }
  })()
  },[])
  const [cancelModal, setCancelModal] = useState(false);
  const [assignModal, setAssignModal] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const [removeModal, setRemoveModal] = useState(false);
  const [selectedRider, setSelectedRider] = useState();
  // const [notifiedStatus, setNotifiedStatus] = useState();
  const [restaurantModal, setRestaurantModal] = useState(false);
  const [flavourDetailModal, setFlavourDetailModal] = useState(false)
  const [flavours, setFlavours] = useState([])
  const data=[1,2,3]

  return (
    <div className={styles.modalContainer}>
      <div className={styles.header}>
        <h2>Order Payment Details</h2>
        <IconButton onClick={modalClose}>
          <Close className={styles.close} />
        </IconButton>
      </div>
    <div className={styles.orderDetails_table}>
      <div className={styles.orderDetails_tableHeader}>
       <h2>
        Order No
       </h2>
       <h2>
       Date&Time
       </h2>
       <h2>
        Total Price
       </h2>
      </div>
      <div className={styles.orderDetails_tableBody}>
       {ordersDetails?.orders?.map((item)=>
      (  
      <div className={styles.orderDetails_tableCell}>
         <span>
          {item.orderNum}
         </span>
         <span>

            {item.date}
            <br/>
            {item.time}

      
          </span>
          <span>
            {item.orderTotal}
          </span>
        </div>)
       )}
      </div>

    </div>
    <div className={styles.orderDetails_otherPaymentsBox}>
      <div className={styles.dealPayment}>
      <h2>Deal Payment Received:
    </h2>
    <span style={{marginLeft:'27px'}}>Rs:{ordersDetails?.dealPayment.toLocaleString()}</span>
      </div>
   <div className={styles.regularPayment}>
   <h3>Regular Payment Received:
  
    </h3>
    <span >Rs:{ordersDetails?.nonDealPayment.toLocaleString()}</span>
   </div>
   
    </div>
    </div>
  );
};

export default OrderPaymentDetails;
