import React, { useEffect, useState } from "react"
import styles from './FilterDate.module.css'
import { Button, IconButton } from "@mui/material"
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import { DateRangePicker } from "react-date-range";
import moment from "moment-timezone";
import CancelIcon from '@mui/icons-material/Cancel';
const FilterDate=({isFilter,makeFilter,startDate1,endDate1,searchOrders}) => {
  const [start, setStartDate] = useState();
  const [end, setEndDate] = useState();
  
  const handleSelect = (ranges) => {
      setStartDate(ranges.selection.startDate);
      setEndDate(ranges.selection.endDate);
      // console.log(ranges);
    };
    const selectionRange = {
      startDate: start,
      endDate: end,
      key: "selection",
    };
    const startDate = moment(start).format("DD-MM-YYYY");
    const endDate = moment(end).format("DD-MM-YYYY");
    // console.log("🚀 ~ file: FilterDate.js:11 ~ FilterDate ~ start:", start)
    return(
        <div className={styles.filterDate}>
            <Button onClick={makeFilter}>
                <span>Filter By Dates</span>
            </Button>
            <div className={styles.filterDatesText}>
            <span>{startDate}-{endDate}</span>
          
            </div>
              {
                isFilter&&
              <div className={styles.DateRangeContainer}>
                <div className={styles.cancel}>
                  <IconButton
                  onClick={makeFilter}
                  >
                  <CancelIcon sx={{color:'#ff7378'}} />

                  </IconButton>
                </div>
               <DateRangePicker
                ranges={[selectionRange]}
                onChange={handleSelect}
                className={styles.dateRangePicker}
                
              />
                <Button 
                onClick={()=>{searchOrders(startDate,endDate)
                  makeFilter()}}
                sx={{
                    padding: '8px',
                    backgroundColor: '#ff7378',
                    border: 'none',
                    color: 'white',
                    cursor: 'pointer',
                    ':hover':{
                        backgroundColor:'white',
                        color:'#ff7378',
                        border:'1px solid #ff7378'

                    }
                }}>
                    Search
                </Button>
                </div>
              }
            </div>
    )
}

export default FilterDate