import styles from './App.module.css';
import { BrowserRouter,Routes,Route, useParams, useNavigate } from 'react-router-dom';
import Login from './pages/login/Login';
import Splash from './pages/Splash/Splash';
import TrackingOrders from './pages/trackingOrders/TrackingOrders';
import MenuScreen from './pages/menuScreen/MenuScreen';
import Switch from 'react-switch'
import { useState,useEffect } from 'react';
import axios from 'axios';
import { REACT_APP_BASE_URL as baseUrl } from './config/keys';
import { ProductState } from './context/ProductProvider';
import PaymentsHistory from './pages/paymentsHistory/PaymentsHistory';
import DeliveredOrders from './pages/deliveredOrders/DeliveredOrders';
import OrdersHistory from './pages/ordersHistory/OrdersHistory';
import RejectedOrders from './pages/rejectedOrders/RejectedOrders';


function App() {

const [restaurantData,setRestaurantData]=useState()
const [isRestaurantOpen,setIsRestaurantOpen]=useState()
const {refresh}=ProductState()
const [changeStatus,setChangeStatus]=useState(false)

useEffect(()=>{
  const userData=JSON.parse(localStorage.getItem('@userData'))
  // console.log("🚀 ~ file: App.js:24 ~ useEffect ~ userData:", userData)
  setRestaurantData(userData)
  setIsRestaurantOpen(userData?.data?.available)
 
},[refresh])


useEffect(()=>{
  (async()=>{
    if(changeStatus){
      // console.log("🚀 ~ file: App.js:35 ~ isRestaurantOpen:", isRestaurantOpen)
      try{
          const result= await axios.post(`${baseUrl}/marts/availabilityStatus`,{
           martId:restaurantData?.data?._id,
           available:isRestaurantOpen
          })
          if(result.data.status==='200'){
           localStorage.setItem('@userData',JSON.stringify({data:result.data.data}))
           alert(`You restaurant has been ${result.data.data.available?'Online':'Offine'}`)
          }
          // console.log("🚀 ~ file: App.js:26 ~ restaurantToggle ~ result:", result.data.data)
          }catch(e){
           console.log(e)
          } 
          setChangeStatus(false)
       }
  })()
 
},[isRestaurantOpen])
const restaurantStatusChange =async()=>{
  setIsRestaurantOpen(!isRestaurantOpen)
  setChangeStatus(true)
  // console.log('restaurantOpen',isRestaurantOpen)
  
}
  const switchButton=
    <div  className={styles.switchButton}>
      <Switch
      onChange={()=>restaurantStatusChange()}
      checked={isRestaurantOpen}
      onColor='#434c56'
      />
     </div>

  return (
    <BrowserRouter>

    {restaurantData&&
    <>
     <div className={styles.restaurantName}>
      <span>{restaurantData.data.name}</span>
     </div>
  <div 
  className={styles.switchButtonBox}>
  {switchButton}
  </div></>
   
      }
      <Routes>
        <Route path='/' element={<Splash />} />
        <Route path='/login' element={<Login docTitle="Login" />}  />
        <Route path='/tracking' element={<TrackingOrders docTitle="Home" />}/>
        <Route path='/menuScreen' element={<MenuScreen docTitle="Menu"  />}  />
        <Route path='/paymentsHistory' element={<PaymentsHistory docTitle="Payments"  />}  />
        <Route path='/deliveredOrders' element={<DeliveredOrders docTitle="Delivered"  />}  />
        <Route path='/ordersHistory' element={<OrdersHistory docTitle="Orders"  />} />
        <Route path='/rejectedOrders' element={<RejectedOrders  docTitle="Rejected"  />} />
      </Routes>
  </BrowserRouter>
  );
}

export default App;
