import React, { createContext, useEffect, useState } from 'react'
import { useContext } from 'react';

const ProductContext=createContext()
const ProductProvider = ({children}) => {
    const [refresh,setRefresh]=useState(false)
    const [isLogin,setIsLogin]=useState(false)
    const [refreshOrder,setRefreshOrder]=useState(false)
    const userData=JSON.parse(localStorage.getItem('@userData'))
    useEffect(()=>{
      if(userData){
        setIsLogin(true)
      }
    },[userData])
  return (
    <ProductContext.Provider value={{refresh,setRefresh,isLogin,setIsLogin,refreshOrder,setRefreshOrder}}>
        {children}
    </ProductContext.Provider>
  )
}

export default ProductProvider;

export const ProductState=()=>{
    return useContext(ProductContext)
}